import axios from "axios";
//import { Get, Put } from "../Request/Api";

export default {
  state: {
    Team: [],
  },
  getters: {
    TeamById(state) {
      return state.Team;
    },
  },
  actions: {
    async addTeam({ commit }, team) {
      try {
        return await axios
          .post(`${process.env.VUE_APP_API_URL}Team/AddTeam`, team)
          .then((response) => {
            if (response.data.status) {
              commit("ADD_TEAM", response.data);
            }
            return response.data;
          });
      } catch (error) {
        //console.error(error);
      }
    },
    async editTeam({ commit }, team) {
      try {
        return await axios
          .put(`${process.env.VUE_APP_API_URL}Team/EditTeam`, team)
          .then((response) => {
            return response.data;
          });
      } catch (error) {
        //console.error(error);
      }
    },
    async deleteTeam({ commit }, id) {
      return await axios
        .delete(`${process.env.VUE_APP_API_URL}Team/${id}`)
        .then((res) => {
          return res.data;
        });
    },
    //http://cashuntapis.rootpointers.net/api/Team/GetTeamsbyExperinceId/fa722166-5155-4148-3c9f-08db29fa6d92
    async GetTeamById({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Team/GetTeamsbyExperienceId/${payload}`
        )
        .then((res) => {
          commit("GetTeam", res.data.data);
          return res.data;
        });
    },
    async AdjustPoints({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Team/AdjustPoints`, payload)
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    GetTeam(state, payload) {
      if (payload != null) {
        state.Team = payload;
      }
    },
    ADD_TEAM(state, team) {
      state.Team.push(team);
    },
  },
};
