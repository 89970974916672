import axios, { Axios } from "axios";
export default {
  state: {
    allAdmin: [],
    adminInfo: {},
    Admin: {},
  },
  getters: {
    GetAdminInfo(state) {
      return state.adminInfo;
    },
    GetAllAdminByExpId(state) {
      return state.allAdmin;
    },
    Getadminbyid(state) {
      return state.Admin;
    },
  },
  actions: {
    async CreateAdmin({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Admin/AddAdmin`, payload)
        .then((response) => {
          if (response.data.status) {
            commit("SetAdminInfo", response.data.data);
          }
          return response.data;
        });
    },
    async Deleteadminbyid({ commit }, payload) {
      return await axios
        .delete(`${process.env.VUE_APP_API_URL}Admin/DeleteAdmin?Id=${payload}`)
        .then((res) => {
          return res.data;
        });
    },
    async ForgetPassword({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}Admin/ResetPassword/${payload}`)
        .then((res) => {
          return res.data;
        });
    },
    async ResetAdminPassword({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Admin/ResetAdminPassword?email=${payload.email}&password=${payload.password}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async GetAdminByExperienceid({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Admin/GetAdminsByExperienceId/${payload}`
        )
        .then((response) => {
          if (response.data.status) {
            commit("SetAdminsByExpId", response.data.data);
            return response.data;
          }
        });
    },
  },
  mutations: {
    SetAdminInfo(state, payload) {
      state.adminInfo = payload;
    },
    SetAdminsByExpId(state, payload) {
      state.allAdmin = payload;
    },
    GetAdmin(state, payload) {
      state.ad = payload;
    },
  },
};
