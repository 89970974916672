import axios, { Axios } from "axios";
import { Get } from "../Request/Api";
import router from "@/router";

export default {
  state: {
    Experince: null,
    viewExperience: null,
    experienceByCurrentexpId: [],
    code: "",
  },
  getters: {
    ExperinceById(state) {
      return state.Experince;
    },
    GetExperincebyExpId(state) {
      return state.viewExperience;
    },
    GetExperienceByCurrentExpId(state) {
      return state.experienceByCurrentexpId;
    },
    GetCode(state) {
      if (
        JSON.parse(localStorage.getItem("EXP_Info")) != null &&
        JSON.parse(localStorage.getItem("EXP_Info")) != undefined
      ) {
        var info = JSON.parse(localStorage.getItem("EXP_Info"));
        state.code = info.code;
      }
      return state.code;
    },
  },
  actions: {
    async SetSortingOrder({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Experience/SetSortingOrder`,
          payload
        )
        .then((res) => {
          return res.data;
        });
    },
    async GetExperinceById({ commit }, payload) {
      //http://cashuntapis.rootpointers.net/api/Experience/GetExperienceByAdmin/1f786cf6-c5ab-48ff-9d8e-3157f7c5ab5c
      return await Get(`Experience/GetExperienceByAdmin/${payload}`).then(
        (res) => {
          commit("GetExperince", res.data.data);
        }
      );
    },
    async DuplicateExperience({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Experience/CreateDuplicateExperience/${payload.experienceId}/${payload.adminId}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async GetExperincebyExpId({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Experience/GetExperienceById/${payload}`
        )
        .then((response) => {
          commit("SetExperiencebyId", response.data.data);
          return response.data;
        })
        .catch((response) => {
          //console.log("Errrrrrrrror ", response);
          router.push("/not found");
        });
    },
    async GetExperienceByCurrentExperienceId({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Experience/GetExperienceByCurrentExperienceId/${payload}`
        )
        .then((response) => {
          commit("SetExpByCurrentExpId", response.data.data);
          return response.data;
        });
    },
    resetExperience({ commit }, payload) {
      commit("resetExperience");
    },
    async GetExperienceCode({ commit }, payload) {
      commit("SetExperienceCode", payload);
    },
    //http://cashuntapis.rootpointers.net/api/Experience/DeleteExperienceById/fa722166-5155-4148-3c9f-08db29fa6d92
    async UpdateExperience({ commit }, payload) {
      try {
        return await axios
          .put(
            `${process.env.VUE_APP_API_URL}Experience/UpdateExperience`,
            payload
          )
          .then((response) => {
            commit("SetExperiencebyId", response.data.data);

            return response.data;
          });
      } catch (error) {
        return null;
      }
    },
    async DeleteExperience({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Experience/DeleteExperienceById/${payload}`
        )
        .then((response) => {
          commit("GetExperince", response.data.data);
          return response.data;
        });
    },
  },
  mutations: {
    GetExperince(state, payload) {
      state.Experince = payload;
    },
    SetExperiencebyId(state, data) {
      state.viewExperience = data;
    },
    SetExperienceCode(state, payload) {
      state.code = payload;
    },
    resetExperience(state, payload) {
      state.viewExperience = null;
    },
    SetExpByCurrentExpId(state, payload) {
      state.experienceByCurrentexpId = payload;
    },
  },
};
