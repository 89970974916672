import axios from "axios";

export const Get = async (url) => {
    return await axios.get(`${process.env.VUE_APP_API_URL}${url}`).then((res)=>{
        return res;
    })
}
export const Delete= async (url) => {
    return await axios.delete(`${process.env.VUE_APP_API_URL}/${url}`).then((res)=>{
        return res;
    })
}
export const Put= async (url) => {
    return await axios.put(`${process.env.VUE_APP_API_URL}/${url}`).then((res)=>{
        return res;
    })
}
