import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",
    component: () => import("../views/PrivacyPolicies/privacyPolicy.vue"),
  },
  {
    path: "/termsandcondition",
    name: "termsandcondition",
    component: () => import("../views/PrivacyPolicies/termsAndCondition.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("../views/PrivacyPolicies/contactUs.vue"),
  },
  {
    path: "/homepage",
    component: () => import("../views/Main/HomePage.vue"),
    redirect: "home",
    children: [
      {
        name: "homepage",
        path: "/home",
        component: () => import("../views/Homepage/Homepage.vue"),
      },
      {
        name: "newexperience",
        path: "/newexperience",
        component: () => import("../views/Experience/newexperience.vue"),
      },
      {
        name: "myexperience",
        path: "/home",
        component: () => import("../views/Experience/myexperience.vue"),
        props: true,
      },
    ],
  },

  {
    path: "/",
    component: () => import("../views/Main/Authlayout.vue"),
    redirect: "/login",
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/Auth/login.vue"),
      },
      {
        path: "/forgotpassword",
        name: "forgotpassword",
        component: () => import("../views/Auth/Forgotpassword.vue"),
      },
      {
        path: "/digitcode/:code",
        name: "digitcode",
        component: () => import("../views/Auth/digitcode.vue"),
        beforeEnter: (to, from, next) => {
          const id = String(to.path);
          if (id.length > 70) {
            next();
          } else {
            next("/invalid-route");
          }
        },
      },
      {
        path: "/resetpassword/:email",
        name: "resetpassword",
        component: () => import("../views/Auth/resetpassword.vue"),
      },
    ],
  },

  {
    path: "/sidebarlayout",
    component: () => import("../views/Main/Dashboardlayout.vue"),
    redirect: "/details",
    children: [
      {
        path: "/details/:id",
        name: "details",
        component: () => import("../views/Details/Details.vue"),
        // props: true,
        // beforeEnter: (to, from, next) => {
        //   const id = String(to.params.id); // Convert id to a string
        //   if (id.length === 36) {
        //     next();
        //   } else {
        //     next("/invalid-route");
        //   }
        // },
      },
      {
        path: "/mission",
        name: "mission",
        component: () => import("../views/Mission/mission.vue"),
      },
      {
        path: "/script",
        name: "script",
        component: () => import("../views/Script/script.vue"),
      },
      {
        path: "/team",
        name: "team",
        component: () => import("../views/team/team.vue"),
      },
      {
        path: "/parcticipants",
        name: "parcticipants",
        component: () => import("../views/Participants/participants.vue"),
      },
      {
        path: "/startend",
        name: "startend",
        component: () => import("../views/Start&end/StartEnd.vue"),
      },

      {
        path: "/activityfeed",
        name: "activityfeed",
        component: () => import("../views/Activityfeed/Activityfeed.vue"),
      },
      {
        path: "/leaderboard",
        name: "leaderboard",
        component: () => import("../views/Leaderboard/leaderboard.vue"),
      },
      {
        path: "/submission",
        name: "submission",
        component: () => import("../views/Submissions/submission.vue"),
      },
      {
        path: "/submissionfolder/:Id",
        name: "submissionfolder",
        component: () => import("../views/Submissions/submissionfolder.vue"),
        props: true,
      },
      {
        path: "/admin",
        name: "admin",
        component: () => import("../views/admin/admin.vue"),
      },
    ],
  },
  {
    path: "*",
    name: "errorpage",
    component: () => import("../views/ErrorPage/Errorpage.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (store.getters["IsLoggedIn"]) {
    if (
      to.name == "login" ||
      to.name == "forgotpassword" ||
      to.name == "digitcode" ||
      to.name == "resetpassword"
    ) {
      next({ name: "homepage" });
    } else {
      next();
    }
  } else if (store.getters["IsLoggedIn"] === false) {
    if (
      to.name == "login" ||
      to.name == "forgotpassword" ||
      to.name == "digitcode" ||
      to.name == "resetpassword" ||
      to.name == "termsandcondition" ||
      to.name == "privacyPolicy" ||
      to.name == "contactus"
    ) {
      next();
    } else {
      next({
        name: "login",
        params: {
          redirect: to.params,
        },
      });
    }
  }
});
export default router;
