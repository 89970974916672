import Vue from "vue";
import Vuex from "vuex";
import Experience from "./Experience";
import Newexpericene from "./newexperience";
import Details from "./Details";
import Login from "./login";
import mission from "./mission";
import startEnd from "./start&end";
import team from "./team";
import activityFeed from "./ActivityFeed";
import admin from "./Admin";
import Leaderboard from "./Leaderboard";
import Submissions from "./Submissions";
import Script from "./Script";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { showLoading: false },
  getters: {},
  mutations: {
    Loading_Spinner(state, payload) {
      state.showLoading = payload;
    },
  },
  actions: {},
  modules: {
    team,
    mission,
    Details,
    Experience,
    Newexpericene,
    startEnd,
    Login,
    activityFeed,
    admin,
    Leaderboard,
    Submissions,
    Script,
  },
});
