import axios from "axios";
//import { Get, Put } from "../Request/Api";

export default {
  state: {
    Startend: [],
  },
  getters: {
    StartendById(state) {
      return state.Startend;
    },
  },
  actions: {
    async addStartendtime({ commit }, experience) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}StartEnd/AddStartEndTimeatExperience`,
          experience
        );

        return response.data;
      } catch (error) {
        //console.error(error);
      }
    },
    async GetStartendById({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}StartEnd/GetStartEndTimeByExperienceId?experienceId=${payload}`
        )
        .then((res) => {
          commit("GetStartend", res.data.data);
          return res.data;
        });
    },
    async DeleteStartEnd({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}StartEnd/DeleteStartEnd?Id=${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async EndStartEnd({ commit }, payload) {
      return await axios
        .put(`${process.env.VUE_APP_API_URL}StartEnd/EndStartEnd?Id=${payload}`)
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    GetStartend(state, payload) {
      state.Startend = payload;
    },
    ADD_STARTEND(state, StartEnd) {
      state.Startend.push(StartEnd);
    },
  },
};
