import axios, { Axios } from "axios";
import { Get } from "../Request/Api";

export default {
  state: {
    allActivityFeed: [],
  },
  getters: {
    GetActivityFeed(state) {
      return state.allActivityFeed;
    },
  },
  actions: {
    async GetActivityFeedByExperienceId({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}ActivityFeed/GetActivityFeedByExperience/${payload}`
        )
        .then((response) => {
          commit("SetActivityFeed", response.data.data);
          return response.data;
        });
    },
    async AddBonus({ commit }, payload) {
      return await axios
        .post(`${process.env.VUE_APP_API_URL}ActivityFeed/AddBonus`, payload)
        .then((res) => {
          return res.data;
        });
    },
    async DeleteActivity({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}ActivityFeed/DeleteActivity?Id=${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    SetActivityFeed(state, payload) {
      state.allActivityFeed = [];
      state.allActivityFeed = payload;
    },
  },
};
