<template>
  <div class="loader_style" v-if="loader">
    <trinity-rings-spinner
      :size="100"
      :color="'#469500'"
    ></trinity-rings-spinner>
  </div>
</template>
<script>
import { TrinityRingsSpinner } from "epic-spinners";
export default {
  name: "LoaderComponent",
  components: {
    TrinityRingsSpinner,
  },
  props: ["loader"],
};
</script>

<style scoped>
.loader_style {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(146, 146, 146, 0.5);
  z-index: 999;
}
</style>
