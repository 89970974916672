import axios, { Axios } from "axios";

export default {
  state: {
    token: null,
    isLoggedin: false,
    loggedinInfo: null,
  },
  getters: {
    getToken: (state) => state.token,
    IsLoggedIn(state) {
      if (JSON.parse(localStorage.getItem("LoginAdmin_INFO")) == null) {
        state.isLoggedin = false;
        return state.isLoggedin;
      }
      state.loggedinInfo = JSON.parse(localStorage.getItem("LoginAdmin_INFO"));
      state.isLoggedin = true;
      return state.isLoggedin;
    },
  },

  actions: {
    async login({ commit }, credentials) {
      const response = await axios.post(
        `${process.env.VUE_APP_API_URL}Admin/Login`,
        credentials
      );
      const token = response.data.token;

      localStorage.setItem(
        "LoginAdmin_INFO",
        JSON.stringify(response.data.data)
      );
      commit("setToken", token);
      return response;
    },
    async Logout({ commit }) {
      commit("Logout");
      return;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      state.loggedinInfo = payload;
      state.isLoggedin = true;
    },
    Logout(state, payload) {
      state.isLoggedin = false;
      state.loggedinInfo = null;
      localStorage.removeItem("LoginAdmin_INFO");
    },
  },
};
