import axios from "axios";
//import { Get, Put } from "../Request/Api";

export default {
  state: {
    automations: [],
    automation: null,
  },
  getters: {
    GetAutomation(state) {
      return state.automations;
    },
    GetAutomationById(state) {
      return state.automation;
    },
  },
  actions: {
    async addAutomation({ commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}Script/AddAutomation`,
          payload
        );

        return response.data;
      } catch (error) {
        //console.error(error);
      }
    },
    async EditAutomation({ commit }, payload) {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}Script/EditAutomation`,
          payload
        );

        return response.data;
      } catch (error) {
        //console.error(error);
      }
    },
    async GetAutomations({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Script/GetAutomations?expId=${payload}`
        )
        .then((res) => {
          commit("GetAutomationsByExperience", res.data.data);
          return res.data;
        });
    },
    async GetAutomationById({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Script/GetAutomationById?Id=${payload}`
        )
        .then((res) => {
          commit("setAutomation", res.data.data);
          return res.data;
        });
    },
    async DeleteAutomation({ commit }, payload) {
      return await axios
        .delete(
          `${process.env.VUE_APP_API_URL}Script/DeleteAutomation?Id=${payload}`
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  mutations: {
    GetAutomationsByExperience(state, payload) {
      state.automations = payload;
    },
    setAutomation(state, payload) {
      state.automation = payload;
    },
  },
};
