<template>
  <v-app>
    <v-main>
      <loader-component v-if="showLoading"></loader-component>
      <router-view/>
    </v-main>
  </v-app>
</template>
<script>
import LoaderComponent from "./components/Loader/loaderComponent.vue"
import { mapState } from "vuex";
export default {
  name: 'App',
  components:{LoaderComponent},
  data: () => ({
    //
  }),
  computed:{
    ...mapState({
      showLoading:(state)=>state.showLoading
    })
  }
};
</script>
<style>
@font-face {
  font-family: "Sofia Pro Regular";
  src: local("Sofia Pro Regular"),
   url(./Fonts/sofiapro-light.otf) format("truetype");
}
</style>

