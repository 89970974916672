import axios, { Axios } from "axios";
import { Get } from "../Request/Api";

export default {
  state: {
    Details: null,
  },
  getters: {
    DetailsById(state) {
      return state.Details;
    },
  },
  actions: {
    async GetDetailsById({ commit }, payload) {
      return await Get(`api/Experience/GetExperienceById/${payload}`).then(
        (res) => {
          commit("GetDetails", res.data.data);
          return res.data.data;
        }
      );
    },
    // async updateForm({ commit }, payload) {
    //     try {
    //       return await axios.put(`${process.env.V}`).then((response)=>{

    //       });
    //
    //     } catch (error) {
    //       console.error("Update Failed: ", error);
    //     }
    //   },
  },

  mutations: {
    GetDetails(state, payload) {
      state.Details = payload;
    },
  },
};
