import axios, { Axios } from "axios";
export default {
    state:{
        teamPositions:[],
    },
    getters:{
        GetTeamPositions(state){
            return state.teamPositions;
        }
    },
    actions:{
        async GetTeamPositionsbyExperience({commit},payload){
            return await axios
                .get(`${process.env.VUE_APP_API_URL}Leaderboard/GetTeamPositionsbyExperience/${payload}`)
                .then((response)=>{
                    if (response.data.status) {
                        commit("SetTeamPositions",response.data.data);
                        return response.data;
                    }
                });
        },
        async SendNotificationByTeam({commit},payload){
            return await axios
                 .post(`${process.env.VUE_APP_API_URL}Notification/SendMessageToTeam`,payload)
                 .then((response)=>{
                    if(response.data.status)
                    {
                        return response.data;
                    }
                 });
        }
    },
    mutations:{
        SetTeamPositions(state,payload){
            state.teamPositions = payload;
        }
    }
}