import axios, { Axios } from "axios";

export default {
  state: {
    experiences: [],
  },
  getters: {
    getExperiences: (state) => state.experiences,
  },
  actions: {
    async addExperience({ commit }, experience) {
      try {
        // const id ='1f786cf6-c5ab-48ff-9d8e-3157f7c5ab5c';
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}Experience/AddExperience`,
          experience
        );

        commit("ADD_EXPERIENCE", response.data);
        return response.data;
      } catch (error) {
        //console.error(error);
      }
    },
  },

  mutations: {
    ADD_EXPERIENCE(state, experience) {
      state.experiences.push(experience);
    },
  },
};
