import axios, { Axios } from "axios";

export default {
  state: {
    missions: [],
    allMission: [],
    missionData: [],
  },
  getters: {
    getmissions: (state) => state.missions,
    getMissionByExpId(state) {
      return state.allMission;
    },
    missionData(state) {
      return state.missionData;
    },
  },
  actions: {
    async AddMissionToAnotherExperience({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Mission/AddMissionToAnotherExperience/${payload.currentExpId}/${payload.missionId}`
        )
        .then((response) => {
          if (response.data.status) {
            return response.data;
          }
        });
    },

    async AddPreMissionToMissions({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Mission/AddPreMissionToMissions?Id=${payload.Id}&expId=${payload.expId}`
        )
        .then((res) => {
          return res.data;
        });
    },
    async DeleteMission({ commit }, id) {
      return await axios
        .delete(`${process.env.VUE_APP_API_URL}Mission/DeleteMission/${id}`)
        .then((res) => {
          return res.data;
        });
    },
    async GetMissionsByExperienceId({ commit }, payload) {
      try {
        return await axios
          .get(
            `${process.env.VUE_APP_API_URL}Mission/GetMissionByExperienceId?experienceId=${payload.experienceId}&Order=${payload.missionOrder}`
          )
          .then((response) => {
            if (response) {
              commit("SetMissionByExpId", response.data.data);
              return response.data;
            } else {
              return response.data.message;
            }
          });
      } catch (error) {
        return error;
      }
    },
    async fetchMissionData({ commit }, payload) {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}Mission/GetMissionById?Id=${payload}`
        );
        const missionData = response.data;
        commit("setMissionData", missionData);
      } catch (error) {
        return error;
      }
    },
    async EditMission({ commit }, payload) {
      try {
        return await axios
          .put(`${process.env.VUE_APP_API_URL}Mission/EditMission`, payload)
          .then((response) => {
            // commit("ADD_MISSION", response.data.data);
            return response.data;
          });
      } catch (error) {
        return error;
      }
    },
    async addMission({ commit }, payload) {
      try {
        return await axios
          .post(`${process.env.VUE_APP_API_URL}Mission/AddMission`, payload)
          .then((response) => {
            // commit("ADD_MISSION", response.data.data);
            return response.data;
          });
      } catch (error) {
        return error;
      }
    },

    async SetMissionOrderByExperience({ commit }, payload) {
      try {
        return await axios
          .post(
            `${process.env.VUE_APP_API_URL}Mission/SetMissionOrderByExperience`,
            payload
          )
          .then((response) => {
            // commit("ADD_MISSION", response.data.data);
            return response.data;
          });
      } catch (error) {
        return error;
      }
    },
  },
  mutations: {
    ADD_MISSION(state, mission) {
      state.missions.push(mission);
    },
    SetMissionByExpId(state, payload) {
      state.allMission = payload;
    },

    SetMissionById(state, payload) {
      state.missions = payload;
    },
    setMissionData(state, data) {
      state.missionData = data;
    },
  },
};
