import axios from "axios";
export default {
  actions: {
    async GetSubmissionByExperience({ commit }, payload) {
      return await axios
        .get(
          `${process.env.VUE_APP_API_URL}Submission/GetSubmissionByExperience/${payload.experienceId}`
        )
        .then((response) => {
          if (response.data.status) {
            commit("SetAllSubmissions", response.data.data);
            return response.data;
          }
        });
    },
    async DownloadSubmission({ commit }, payload) {
      return await axios
        .post(
          `${process.env.VUE_APP_API_URL}Submission/DownloadSubmission`,
          payload
        )
        .then((res) => {
          return res.data;
        });
    },
  },
  state: {
    submissions: [],
  },
  mutations: {
    SetAllSubmissions(state, payload) {
      state.submissions = payload;
    },
  },
  getters: {
    GetAllSubmissions(state) {
      return state.submissions;
    },
  },
};
